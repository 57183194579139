.container-slider{
    position: relative
}
#slider{
    position: relative;
    flex-direction: row;
    flex: 1;
    overflow-x: hidden;
    white-space: nowrap;
  }

.arrow-slider{ 
    position: absolute;
    top: calc(50% - 20px);
    width: 40px; 
    height: 40px;
    border-radius: 100% !important;
    opacity: 0.8;
    color: #fff !important;
}

.arrow-slider:hover{ 
    opacity: 1;
}

.arrow-right{
    right: 0
}

.arrow-left{
    left: 0
}


@media (max-width: 1024px) {
    #slider{
        overflow-x: scroll;
    }
}