.container-header {
    background-color: #f5f5f5;
}
.live-container {
    display: flex;
    width: 75%;
    align-items: stretch;
    margin: 0 auto;
    justify-content: center;
}

.live{
    width: 100%;
    padding: 0 8px;
}

@media (max-width: 768px){
    .live-container {
        width: 100%;
    }
}