.show-link{
    text-decoration: none;
    color: #000
}

.more-shows-button{
    background-color: #fff !important;
    border: var(--bs-card-border-width) solid var(--bs-btn-hover-border-color);
    border-color: var(--bs-btn-hover-border-color) !important;
    &:hover{
        color: var(--bs-primary) !important;
    }
}