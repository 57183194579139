.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    padding-top: 56.25%;
    display: block;
    content: "";
}

.embed-responsive iframe{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.gif-en-vivo{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 100;
}

@media (max-width: 767px) {
    .gif-en-vivo{
        top: 5px;
        left: 5px;
    }
    .gif-en-vivo > img{
        width: 90px;
    }
}